<template>
  <div>
    <c-table
      ref="materialTable"
      title="규정집 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      selection="single"
      rowKey="chemMaterialRuleId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
          <c-btn label="선택" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'chem-material-pop',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        type: 'single',
        plantCd: '',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      listUrl: '',
      grid: {
        columns: [
          {
            name: 'title',
            field: 'title',
            label: '규정집 제목',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
        height: '100%'
      },
      searchParam: {
        materialName: '',
        keNo: '',
        casNo: '',
        plantCd: '',
      },
      editable: true,
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    }
  },
  methods: {
    init() {
      this.listUrl = selectConfig.mdm.mam.rule.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['materialTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '규정집을 선택하세요.', // 자재를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
  }
};
</script>
